import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

const SECONDS_BEFORE_SHOWING_BANNER_AGAIN = 172800 // 2 days in seconds
const MILLISECONDS_PER_SECOND = 1000
const ANIMATION_DELAY = 50
const TRANSITION_DURATION = 700

export default class extends Controller {
  static targets = ["banner"]

  connect() {
    const lastClosed = localStorage.getItem('sunsetBannerLastClosed')

    // Show banner if never closed before
    if (!lastClosed) {
      this.showBannerWithDelay()
      return
    }

    const lastClosedDate = new Date(parseInt(lastClosed))
    const timeAgo = new Date()
    timeAgo.setTime(timeAgo.getTime() - (SECONDS_BEFORE_SHOWING_BANNER_AGAIN * MILLISECONDS_PER_SECOND))

    if (lastClosedDate < timeAgo) {
      this.showBannerWithDelay()
    }
  }

  showBannerWithDelay() {
    setTimeout(() => {
      this.bannerTarget.classList.remove('hidden')
      requestAnimationFrame(() => {
        enter(this.bannerTarget)
      })
    }, ANIMATION_DELAY)
  }

  close() {
    this.hide()
    localStorage.setItem('sunsetBannerLastClosed', new Date().getTime().toString())
  }

  show() {
    enter(this.bannerTarget)
  }

  hide() {
    this.bannerTarget.classList.add('translate-y-full')
    setTimeout(() => {
      this.bannerTarget.classList.add('hidden')
    }, TRANSITION_DURATION)
  }
}
